<template>
  <div class="data-auth">
    <el-main>
      <el-tabs v-model="activeTabName" type="border-card" lazy>
        <el-tab-pane name="data-auth-setting" v-permission="['data_auth:setting']" style="min-height: 500px;">
          <span slot="label"><i class="el-icon-setting"></i> 数据权限设置</span>
          <data-auth-setting/>
        </el-tab-pane>

        <el-tab-pane name="data-auth-approve" v-permission="['data_auth:approve']" style="min-height: 500px;">
          <span slot="label"><i class="el-icon-key"></i> 数据申请审批</span>
          <data-auth-approve/>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </div>
</template>

<script>
import DataAuthSetting from "@/components/dataAuth/dataAuthSetting";
import DataAuthApprove from "@/components/dataAuth/dataAuthApprove";

export default {
  name: "dataAuth",
  components: {DataAuthApprove, DataAuthSetting},
  data() {
    return {
      activeTabName: 'data-auth-setting'
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.data-auth {

}
</style>
