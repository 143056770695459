<template>
  <div class="system-setting">
    <el-row class="item">
      <span class="label">数据权限自动分配策略</span>
      <el-button size="mini" @click="edit('hermes_config_data_auth_setting', 'source')">
        编辑
      </el-button>

      <el-button size="mini" @click="edit('hermes_config_data_auth_setting', 'ui')">
        图形化编辑
      </el-button>

      <el-button size="mini" @click="edit('hermes_config_data_auth_setting', 'ui-setting')">
        图形界面设置
      </el-button>

    </el-row>

    <el-row class="item">
      <span class="label">项目信息开放范围设置</span>
      <el-button size="mini" @click="edit('hermes_config_project_auth_setting', 'source')">
        编辑
      </el-button>

      <el-button size="mini" @click="edit('hermes_config_project_auth_setting', 'ui')">
        图形化编辑
      </el-button>

      <el-button size="mini" @click="edit('hermes_config_project_auth_setting', 'ui-setting')">
        图形界面设置
      </el-button>

    </el-row>

    <el-row>
      <el-button @click="grantDataAuth('t_schedule')">
        日程数据重新授权
      </el-button>
      <el-button @click="grantDataAuth('t_expense')">
        费用数据重新授权
      </el-button>
      <el-button @click="grantDataAuth('t_project_workflow')">
        业务进度数据重新授权
      </el-button>
      <el-button @click="grantDataAuth('t_project')">
        项目数据重新授权
      </el-button>
    </el-row>

    <el-row style="color: #666;font-size: 13px;">
      <el-col>备注:</el-col>
      <el-col>1. 修改【数据权限自动分配策略】中【分配日程】相关操作后，需要重新执行【日程数据重新授权】;</el-col>
      <el-col>2. 修改【数据权限自动分配策略】中【分配费用】相关操作后，需要重新执行【费用数据重新授权】;</el-col>
      <el-col>3. 修改【项目信息开放范围设置】中【项目文件】【项目评估历史】【项目会议历史】【项目任务进展】配置时，需要重新执行【项目数据重新授权】;</el-col>
      <el-col>4. 修改【项目信息开放范围设置】中【项目审计】配置时，需要重新执行【业务进度数据重新授权】。</el-col>
    </el-row>

    <el-dialog :title="mode === 'source' ? '参数修改' : '图形界面设置'"
               :visible.sync="editDialog"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center
               width="900px">

      <el-input
        type="textarea"
        class="setting-value"
        :rows="20"
        placeholder=""
        v-model="editJsonString">
      </el-input>


      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="'参数设置'"
               :visible.sync="uiEditDialog"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center
               width="900px">

      <div class="json-editor-wrap">
        <div id="json_editor_holder"></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="uiEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>

    </el-dialog>


  </div>
</template>

<script>

import {getSystemSetting, regrant, updateSystemSetting} from "@/api/systemSetting";
import {JSONEditor} from "@/common/jsoneditor";

export default {
  name: "dataAuthSetting",
  data() {
    return {
      setting: {},
      mode: '',
      editJsonString: '',
      editDialog: false,
      uiEditDialog: false,
      jsonEditor: null
    }
  },

  created() {
  },

  watch: {},

  mounted() {

    JSONEditor.defaults.options.theme = 'bootstrap4';
    JSONEditor.defaults.options.iconlib = 'fontawesome5';

    // JSONEditor.defaults.options.disable_collapse = false;
    JSONEditor.defaults.options.disable_edit_json = true;
    JSONEditor.defaults.options.disable_properties = true;
    JSONEditor.defaults.options.disable_array_delete_all_rows = true;
    JSONEditor.defaults.options.disable_array_delete_last_row = true;
    JSONEditor.defaults.options.remove_button_labels = true;
    JSONEditor.defaults.options.prompt_before_delete = false;

    // JSONEditor.defaults.editors.object.options = {collapsed: true}; // = true;
    // JSONEditor.defaults.editors.array.options = {collapsed: true}; // = true;

  },

  methods: {

    async edit(key, mode) {

      if (mode === 'ui') {
        this.uiEditDialog = true;
      } else {
        this.editDialog = true;
      }

      let res = await getSystemSetting(key);
      if (res.success) {
        this.setting = res.data;
        this.mode = mode;

        if (mode === 'source') {
          this.editJsonString = this.setting.value;
        } else if (mode === 'ui-setting') {
          this.editJsonString = this.setting.jsonSchema;
        } else if (mode === 'ui') {

          let schema = {
            "type": "object"
          };

          if (this.setting.jsonSchema) {
            schema = JSON.parse(this.setting.jsonSchema);
          }

          if (this.jsonEditor) {
            this.jsonEditor.destroy();
          }

          const element = document.getElementById('json_editor_holder');
          const editor = new JSONEditor(element,
            {
              schema: schema,
              startval: JSON.parse(this.setting.value)
            }
          );

          this.jsonEditor = editor;
        }
      }
    },

    async save() {

      try {
        if (this.mode === 'source') {
          this.setting.value = JSON.stringify(JSON.parse(this.editJsonString), null, 2);
        } else if (this.mode === 'ui-setting') {
          this.setting.jsonSchema = JSON.stringify(JSON.parse(this.editJsonString), null, 2);
        } else if (this.mode === 'ui') {
          this.setting.value = JSON.stringify(this.jsonEditor.getValue(), null, 2);
        } else {
          return;
        }
      } catch (e) {
        this.$message.error('参数有误，请检查是否为有效的 json 字符串');
        return;
      }

      const response = await updateSystemSetting(this.setting);
      if (response.success) {
        this.editDialog = false;
        this.uiEditDialog = false;
      }
    },

    async grantDataAuth(target) {

      let response = await regrant(target);
      if (response.success) {
        this.$message.success("请求已提交，稍等片刻即可完成");
      } else {
        this.$message.error(response.message);
      }

    }
  }
}
</script>

<style scoped lang="scss">

.system-setting {
  .item {
    .label {
      font-size: 14px;
      margin-right: 10px;
    }

    .btn {

    }
  }
}


#json_editor_holder {

}

.json-editor-wrap {
  height: 500px;
  overflow-y: scroll;
}

</style>

<style lang="scss">

$fa-font-path: "../../styles/fontawesome-free-5.15.4-web/webfonts";

@import "../../styles/fontawesome-free-5.15.4-web/scss/fontawesome";
@import "../../styles/fontawesome-free-5.15.4-web/scss/solid";

.system-setting {

  div.json-editor-wrap {
    @import "../../styles/bootstrap/scss/bootstrap";

    .card-title {
      font-size: 14px;
      label {
        padding-left: 5px;
      }
    }

    .form-control {
      font-size: 14px;
    }

    .btn-group {
      padding: 10px 0;
    }

    .btn-secondary {
      margin-right: 4px;
      background-color: #17233d88;
    }

    .je-object__container {
      margin-bottom: 15px;
    }

    .json-editor-btntype-toggle {
      width: 27px;
    }

    .form-check-input {
      width: 18px;
      height: 18px;
    }

    .form-check-label {
      line-height: 26px;
      padding-left: 5px;
    }

    .btn {
      border-radius: 0 !important;
      &:focus {
        box-shadow: none !important;
      }
    }

  }

  .el-row {
    margin: 20px 0;
  }

  .setting-value {
    textarea.el-textarea__inner {
      font-family: Consolas, sans-serif !important;
    }
  }


}
</style>


